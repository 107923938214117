import { Link } from '@remix-run/react';

export function Footer() {
  return (
    <footer className="mb-4 flex flex-col justify-between gap-y-5 border-t border-black px-6 py-4 text-center sm:flex-row sm:gap-y-0 sm:text-left">
      <div className="text-sm">&copy; {new Date().getFullYear()}, Portfolio Dragon</div>
      <nav className="mr-4 flex flex-row gap-4 text-center text-sm text-grey-700 sm:text-left">
        <Link to="/platform-service-terms">Platform Service Terms</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/cookie-policy">Cookie Policy</Link>
        <Link to="https://www.arabesque.com/ai/contact-us/">Contact Us</Link>
      </nav>
    </footer>
  );
}
