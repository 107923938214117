import persistedQueries from '@aether/client-graphql/generated/persisted-documents.json';
import { ConsentBanner } from '@aether/tracking/consent-banner';
import { getAppOrigin, getBffProxyOrigin } from '@aether/utils/services/linking-service';
import { type UserRole } from '@aether/validation';
import { json, type LinksFunction, type LoaderFunctionArgs } from '@remix-run/cloudflare';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ClientOnly } from 'remix-utils/client-only';

import stylesheet from '~/tailwind.css?url';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

export type User = { displayName?: string | null; role: UserRole };

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  {
    rel: 'preload',
    href: '/fonts/GeistVF.woff2',
    as: 'font',
    type: 'font/woff2',
  },
];

export const loader = async ({ request, context: { cloudflare } }: LoaderFunctionArgs) => {
  const host = request.headers.get('host');
  const cookieHeader = request.headers.get('Cookie');
  const isLocal = host?.includes('localhost');
  const protocol = request.headers.get('x-forwarded-proto') || 'https';
  const origin = host ? `${protocol}://${host}` : null;

  let data = null;
  if (cookieHeader?.includes('session')) {
    // workaround for local subdomains see https://github.com/nodejs/node/issues/50871
    const url = isLocal ? 'http://127.0.0.1:4000/graphql' : `${getBffProxyOrigin(origin)}/graphql`;
    const authQueryHash =
      Object.entries(persistedQueries).find(([_, query]) => query.includes('query AuthQuery'))?.[0] || '';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Cookie: cookieHeader || '',
        // workaround for local subdomains see https://github.com/nodejs/node/issues/50871
        ...(isLocal ? { host: `bff.arabesque.localhost` } : {}),
      },
      body: JSON.stringify({
        extensions: { persistedQuery: { version: 1, sha256Hash: authQueryHash } },
        operationName: 'AuthQuery',
      }),
    });
    data = await response.json<{ data?: { me?: User } }>();
  }

  return json({
    ENV: {
      POSTHOG_KEY: cloudflare.env.POSTHOG_KEY,
      POSTHOG_HOST: cloudflare.env.POSTHOG_HOST,
      FUND_SEARCH_URI: cloudflare.env.FUND_SEARCH_URI,
    },
    origin,
    user: data?.data?.me,
  });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function Root() {
  const data = useLoaderData<typeof loader>();
  const isAuthenticated = !!data.user && data?.user?.role !== 'public';
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen">
        <div className="m-auto grid h-full min-h-screen w-full grid-rows-[auto_1fr_auto] overflow-y-hidden">
          <Header isAuthenticated={isAuthenticated} host={getAppOrigin(data?.origin)} className="px-6 pt-6" />
          <Outlet />
          <Footer />
        </div>
        {/* The SSR consent banner produces hydration errors, most likely because of how we're fetching the cookie */}
        <ClientOnly>{() => <ConsentBanner />}</ClientOnly>
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{ __html: `window.ENV = ${JSON.stringify(data.ENV)}` }} />
        <Scripts />
      </body>
    </html>
  );
}
